import React from 'react'
import Button from '../../Global/Button'
import LazyLoad from 'react-lazyload'
import Heading from '../../Global/Heading'
import { Container } from '../../../layouts'
import { Row, Col } from '../../../layouts/grid.css'
import * as S from './List.css'
import * as G from '../../Global/Heading/Heading.css'
import { colors } from '../../../../lib/constants'

export const ProjectCard = ({ slug, proj, isEven }) => {
  return (
    <Container color={isEven ? colors.white : null}>
      <S.ProjectCard>
        <LazyLoad once offset={200}>
          <S.ProjectImageLink to={slug} className="nounderline">
            <S.ProjectImageContainer>
              <S.ProjectImage
                src={proj?.feature_image?.publicURL}
                alt={proj.project_title}
              />
            </S.ProjectImageContainer>
          </S.ProjectImageLink>
        </LazyLoad>
        <S.ProjectActions>
          <G.Heading2Size3 hasLine lineSize={5} lineWidth={100}>
            {proj.project_title}
          </G.Heading2Size3>
          <Button border primary={false} type="link" href={slug}>
            View project
          </Button>
        </S.ProjectActions>
      </S.ProjectCard>
    </Container>
  )
}

export const Projects = ({ projects, heading }) => {
  return (
    <S.Projects>
      <Heading level={3} center>
        {heading}
      </Heading>
      <Row gutter={10}>
        {projects.map(
          (
            {
              node: {
                frontmatter: proj,
                fields: { slug },
              },
            },
            index
          ) => {
            return (
              <>
                <Col md={12} key={index}>
                  <ProjectCard proj={proj} slug={slug} isEven={index % 2} />
                </Col>
                {index + 1 !== projects.length && <hr />}
              </>
            )
          }
        )}
      </Row>
    </S.Projects>
  )
}

Projects.propTypes = {}

Projects.defaulProps = {}
