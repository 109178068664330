import React from 'react'
import Heading from '../Heading'
import Button from '../Button'
import { Row, Col } from '../../../layouts/grid.css'
import { colors } from '../../../../lib/constants'
import BtnIcon from '../Icon/BtnIcon'
import ArrowRight from '../Icon/ArrowRight'
import * as S from './CTA.css'
import CTAImg from '/static/img/cta-03.jpg'
import * as G from '../../Global/Heading/Heading.css'

const CTA = ({
  headingColor,
  heading,
  content,
  btnText,
  btnLink,
  image,
  rowReverse,
  roundedImage,
  bgColor,
  btnIcon,
  hasShadow,
}) => {
  return (
    <S.CTA bgColor={bgColor} hasShadow={hasShadow}>
      <Row itemsCenter rowReverse={rowReverse} columnsReverseMobile>
        <Col md={7}>
          <S.Box widthPadding={rowReverse}>
            <G.Heading2Size3 color={headingColor ? headingColor : colors.white}>
              {heading}
            </G.Heading2Size3>
            <p>{content}</p>

            {btnLink &&
              btnText &&
              (btnIcon ? (
                <Button type="link" transparent href={btnLink}>
                  {btnText} &nbsp;
                  <BtnIcon>
                    <ArrowRight />
                  </BtnIcon>
                </Button>
              ) : (
                <Button type="link" href={btnLink}>
                  {btnText}
                </Button>
              ))}
          </S.Box>
        </Col>
        <Col md={5} style={{ lineHeight: 0 }} image={bgColor ? image : null}>
          <S.Image rounded={roundedImage} src={image} alt="" />
        </Col>
      </Row>
    </S.CTA>
  )
}

CTA.defaultProps = {
  heading: 'Have an exciting idea you want to bring to life?',
  content: "Let's talk about the possibilities",
  btnText: 'Contact us',
  btnLink: '/sayhi/',
  image: CTAImg,
  rowReverse: false,
  roundedImage: false,
  btnIcon: false,
  headingColor: '',
}

export default CTA
