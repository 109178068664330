import React from 'react'
import PropTypes from 'prop-types'
import * as S from './BtnIcon.css'

const BtnIcon = props => {
  return props.type === 'link' ? (
    <S.A {...props}>{props.children}</S.A>
  ) : (
    <S.Span>{props.children}</S.Span>
  )
}

BtnIcon.defaultProps = {
  type: 'span',
}

BtnIcon.propTypes = {
  type: PropTypes.oneOf(['link', 'span']),
}

export default BtnIcon
