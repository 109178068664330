import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { buttons, colors } from '../../../../lib/constants'

const baseStyle = css`
  ${buttons.pinkhollow}
  background-color: ${colors.pink};
  color: ${colors.white};
  cursor: pointer;
  padding: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::before {
    display: none;
  }
`

export const A = styled(Link)`
  ${baseStyle}
`

export const Span = styled.span`
  ${baseStyle}
`
