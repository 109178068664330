import styled from 'styled-components'
import { Link } from 'gatsby'
import { textMd, transitions, media } from '../../../../lib/constants'
import Heading from '../../Global/Heading'

export const Projects = styled.div``

export const ProjectCard = styled.div``

export const ProjectImageLink = styled(Link)`
  display: block;
`

export const ProjectImageContainer = styled.div``

export const ProjectImage = styled.img`
  transition: opacity ${transitions.slow};
  width: 100%;

  &:hover {
    opacity: 0.75;
  }
`

export const ProjectActions = styled.div`
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  top: -4rem;
  margin-bottom: -4rem;

  ${media.tablet`
    top: unset;
    margin-bottom: unset;

    flex-direction: column;
    align-items: center;
  `}

  h2 {
    margin-bottom: 0;
  }
`

export const ProjectTitle = styled(Heading)`
  font-size: 48px;
  line-height: 1.15;
  margin-bottom: 0;

  ${media.tablet`
    font-size: 30px;
    margin-bottom: 1rem;
    text-align: center;

    &::after {
      display: none;
    }
  `}
`

export const ProjectClient = styled.span`
  ${textMd}
  margin-bottom: 1rem;
  display: inline-block;
`

export const ProjectDescription = styled.div`
  margin-bottom: 28px;
`
