import React from 'react'
import { graphql } from 'gatsby'
import { Layout, Container } from '../layouts'
import { Helmet } from 'react-helmet'
import Hero from '../components/Global/Hero'
import CTA from '../components/Global/CTA'
import { Projects } from '../components/Projects/List'
import { colors } from '../../lib/constants'

const ProjectsPage = ({ data }) => {
  const { projects } = data
  const { cta } = data.pageDate.frontmatter

  return (
    <Layout>
      <Helmet>
        <title>Websites, Web-based platforms & Apps - TechEquipt</title>
        <meta
          name="description"
          content="TechEquipt cares about the value our projects provide our clients. They are built with scalability & flexibility.  Have a look at some of our projects here."
        />
      </Helmet>

      <Hero title={`Our Work`} small />

      <Projects projects={projects.edges} />

      {cta.title && cta.image.publicURL && (
        <Container>
          <CTA
            headingColor={colors.blue}
            bgColor={colors.white}
            heading={cta.title}
            content={cta.content}
            btnText={cta.button_text}
            btnLink={cta.button_link}
            image={cta.image.publicURL}
          />
        </Container>
      )}
    </Layout>
  )
}

export default ProjectsPage

export const pageQuery = graphql`
  query ProjectsPageTemplate {
    pageDate: markdownRemark(
      frontmatter: { templateKey: { eq: "projects-page" } }
    ) {
      frontmatter {
        cta {
          title
          content
          image {
            publicURL
          }
          button_text
          button_link
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-post" } } }
      sort: { order: ASC, fields: [frontmatter___project_title] }
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            name
            position
            thumbnail {
              publicURL
            }
            description
            feature_image {
              publicURL
            }
            project_title
            client
            date
            colour
          }
        }
      }
    }
  }
`
