import styled, { css } from 'styled-components'
import { media, colors, shadows } from '../../../../lib/constants'

export const CTA = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 3.5rem;
  color: ${colors.white};

  ${(props) =>
    props.hasShadow &&
    css`
      box-shadow: ${shadows.projectCard};
      border-radius: 10px;
      overflow: hidden;
    `}

  ${(props) =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
      padding-bottom: 0;

      h3 {
        color: ${colors.blue};
      }

      p {
        color: ${colors.grey};
      }

      ${Box} {
        padding: 60px 100px;
      }

      ${media.desktop`
        ${Box} {
          padding: 50px;
        }
      `}

      ${media.tablet`
        ${Box} {
          padding: 30px;
        }

        img {
          margin-bottom: 0;
        }
      `}
    `}
`

export const Box = styled.div`
  p {
    margin-bottom: 1.75rem;
  }

  ${media.tablet`
    text-align: left;

    h3 {
      margin-bottom: 0.75rem;
    }
  `}

  ${(props) =>
    props.widthPadding &&
    css`
      padding: 60px;

      ${media.tablet`
        padding: 0;
      `}
    `}
`

export const ImageWrap = styled.div`
  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
      background-size: cover;
      background-repeat: no-repeat;

      img {
        opacity: 0;
      }
    `}
`

export const Image = styled.img`
  width: 100%;
  margin-bottom: 0;

  ${media.tablet`
    margin-bottom: 1rem;
  `}

  ${(props) =>
    props.rounded &&
    css`
      border-radius: 10px;
    `}
`
